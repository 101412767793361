import axios from 'axios'

import config from '../../../../config'
import { getToken } from '../../tools/tokens'
import { setUserDetails, setRequest, resetRequest } from './helpers'
import Cookies from 'js-cookie'

const _axios = async (
  dispatch,
  method,
  url,
  data = {},
  additionalParams = {},
  stringifyIt = false
) => {
  resetRequest(dispatch)
  const { apiProxy, apiKey, internalName } = config
  try {
    const token = await getToken()
    const response = await axios({
      url: `${apiProxy}${url}`,
      method,
      params: {
        site: internalName,
        ...additionalParams
      },
      headers: {
        Authorization: token,
        'x-api-key': apiKey
      },
      data: stringifyIt ? JSON.stringify(data) : data,
      contentType: 'application/json'
    })

    setRequest(dispatch, { status: 'SUCCESS' })

    return response.data
  } catch (err) {
    setRequest(dispatch, { err: err?.response?.data })
    throw err
  } finally {
    setRequest(dispatch, { status: 'PRECALL' })
  }
}

export const addToUser = (data) => (dispatch) => {
  setUserDetails(dispatch, data)
}

export const updateUserDetails = (data) => async (dispatch) => {
  const method = 'POST'
  const url = '/identity/user'

  try {
    const user = await _axios(dispatch, method, url, data, true)
    setUserDetails(dispatch, user)
  } catch (err) {}
}
export const updateUserDetailsWithoutApi = (data) => async (dispatch) => {
  try {
    setUserDetails(dispatch, data)
  } catch (err) {}
}
export const clearUserWatchList = (data) => async (dispatch) => {
  try {
    setUserDetails(dispatch, data)
  } catch (err) {}
}

export const updatePhoneNumber = (details, user) => async (dispatch) => {
  const method = 'POST'
  const url = '/identity/user/updatePhone'
  const additionalParams = {
    userId: user.userId
  }

  // delete details.userId;

  try {
    const user = await _axios(dispatch, method, url, details, additionalParams)
    setUserDetails(dispatch, user)
  } catch (err) {
    throw err
  }
}

export const changePassword = (data) => async (dispatch) => {
  const method = 'POST'
  const url = '/identity/password'

  try {
  } catch (err) {
    console.log(err)
    throw err
  }
}

// get subsciption
export const getMySubscription = (user) => async (dispatch) => {
  try {
    const method = 'GET'
    const url = '/subscription/user'
    const additionalParams = {
      userId: user.userId
    }
    const subs = await _axios(dispatch, method, url, {}, additionalParams)

    const subscriptionStatus = subs.subscriptionInfo.subscriptionStatus

    if (subscriptionStatus === 'COMPLETED') {
      setUserDetails(dispatch, { ...subs, isSubscribed: true })
    } else {
      setUserDetails(dispatch, subs)
    }
  } catch (err) {}
}

// export const setParentalControls = (bol) => async (dispatch) => {
//   clearError(dispatch);

//   try {
//     const token = await getToken();
//     const options = {
//       method: 'PUT',
//       url: `${apiProxy}/identity/parental`,
//       data: { parentalControl: bol },
//       headers: {
//         Authorization: token,
//         'x-api-key': apiKey,
//       },
//     };
//     await axios(options);
//     setUserDetails(dispatch, { parentalControl: bol });
//   } catch (err) {
//     setError(dispatch, err);
//   }
// };

export const getMyBillingHistory = () => async (dispatch) => {
  const method = 'GET'
  const url = '/payments/billing-history'
  try {
    const billing = await _axios(dispatch, method, url)
    setUserDetails(dispatch, { billingHistory: billing })
  } catch (error) {}
}

export const cancelMySubscription = () => async (dispatch) => {
  const method = 'PUT'
  const url = '/subscription/cancel'

  try {
    const statusSubscription = await _axios(dispatch, method, url)
    setUserDetails(dispatch, { statusSubscription })
  } catch (error) {
    console.log(error)
  }
}

// dispatch,
//   method,
//   url,
//   data = {},
//   additionalParams = {},
//   stringifyIt = false

export const reactivateSubscription = () => async (dispatch) => {
  const method = 'PUT'
  const url = '/subscription/subscribe'
  const data = {
    action: 'reactivate',
    platform: 'web_browser'
  }
  try {
    const statusSubscription = await _axios(dispatch, method, url, data)
    setUserDetails(dispatch, { statusSubscription })
  } catch (error) {
    console.log(error)
  }
}

export const getCardsList = () => async (dispatch) => {
  const url = '/subscription/card'
  const method = 'GET'

  const cards = await _axios(dispatch, method, url)

  setUserDetails(dispatch, { cards })
}

// dispatch,
//   method,
//   url,
//   data = {},
//   additionalParams = {},
//   stringifyIt = false

export const fetchDevices = (user) => async (dispatch) => {
  const method = 'GET'
  const url = '/user/device'
  const additionalParams = {
    userId: user.userId
  }

  const devices = await _axios(dispatch, method, url, {}, additionalParams)
  setUserDetails(dispatch, { devices })
}

export const removeDevice = (deviceId) => async (dispatch) => {
  const { internalName } = config
  const method = 'DELETE'
  const url = '/user/device/desync'

  const params = {
    deviceId,
    site: internalName
  }

  await _axios(dispatch, method, url, params, params)
}

export const activeNewDevice =
  (activationCode, user = {}) =>
  async (dispatch) => {
    const method = 'POST'
    const url = '/user/device/sync'
    const data = {
      userId: user.userId,
      activationCode
    }

    return await _axios(dispatch, method, url, data)
  }

// dispatch,
//   method,
//   url,
//   data = {},
//   additionalParams = {},
//   stringifyIt = false

export const fetchHistory =
  (page = 1) =>
  async (dispatch) => {
    const method = 'GET'
    const url = '/user/userId/history/video'
    const languageCode = Cookies.get('userLanguage') || 'en'
    const additionalParams = {
      version: 'v2',
      limit: 25,
      page,
      languageCode
    }
    const watchHistory = await _axios(
      dispatch,
      method,
      url,
      {},
      additionalParams
    )
    setUserDetails(dispatch, { watchHistory })
  }

export const deleteHistory = (videoIds) => async (dispatch) => {
  const method = 'DELETE'
  const url = '/user/userId/history/video'
  // const url = '/user/userId/history';
  // ?version=v2&videoIds=0000015b-9237-d1d7-a37b-d6777f470000&site=staging-hoichoitv'
  const additionalParams = {
    version: 'v2',
    videoIds
    // siteName: config.internalName,
  }

  try {
    await _axios(dispatch, method, url, {}, additionalParams)
    fetchHistory(1)(dispatch)
  } catch (err) {}
}

export const setParentalControlToggle =
  (parentalControl, refreshToken) => async (dispatch) => {
    const { apiKey, apiProxy } = config
    resetRequest(dispatch)
    try {
      const token = await getToken()
      await axios({
        url: '/identity/parental',
        baseURL: apiProxy,
        method: 'PUT',
        // params: {
        //   site: internalName,
        // },
        headers: {
          Authorization: token,
          'x-api-key': apiKey
        },
        data: { parentalControl }
      })
      // const _parentalControl = !parentalControl
      //   ? { parentalPin: null, parentalRating: 'none' }
      //   : {};
      setRequest(dispatch, { status: 'SUCCESS' })
      setUserDetails(dispatch, { parentalControl })
    } catch (err) {
      setRequest(dispatch, { err: err.response.data })
    } finally {
      setRequest(dispatch, { status: 'PRECALL' })
    }
  }
